/* global currencyList, currencyFirst, currencyLast */
import * as React from 'react';
import { render } from 'react-dom';
import CurrenciesLeftTable from './components/CurrenciesLeftTable';
import LangSwitcher from './components/LangSwitcher';

const currenciesLeftTable = document.getElementById('currenciesLeftTable');

if (currenciesLeftTable) {
  render(React.createElement(CurrenciesLeftTable, {
    currencyList,
    currencyLeftId: currencyFirst !== -1 ? currencyFirst.toString() : null,
    currencyRightId: currencyLast !== -1 ? currencyLast.toString() : null,
    trans: window.trans,
  }), currenciesLeftTable);
}

const langSwitcher = document.getElementById('langSwitcher');

if (langSwitcher) {
  render(React.createElement(LangSwitcher, {
    langArray: JSON.parse(langSwitcher.dataset.langs),
  }), langSwitcher);
}
