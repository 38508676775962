import * as React from 'react';
import axios from 'axios';
import { CurrencyList, CurrencyItem } from '../types';

const COL_LEFT = 'left';
const COL_RIGHT = 'right';

export default class CurrenciesLeftTable extends React.PureComponent
  <CurrenciesLeftTableProps, CurrenciesLeftTableState> {
  public state: CurrenciesLeftTableState = {
    selectedItemLeft: null,
    selectedItemRight: null,
    hiddenGroupsIdListLeft: {},
    hiddenGroupsIdListRight: {},
    hiddenItemsIdListLeft: {},
    hiddenItemsIdListRight: {},
    searchTextLeft: '',
    searchTextRight: '',
  };

  public static defaultProps = {
    currencyLeftId: null,
    currencyRightId: null,
  };

  public componentDidMount(): void {
    const { currencyLeftId, currencyRightId } = this.props;

    if (currencyLeftId && currencyRightId) {
      this.updateAvailableCurrencies(currencyLeftId, COL_RIGHT);
      this.updateAvailableCurrencies(currencyRightId, COL_LEFT);
      this.setState({
        selectedItemRight: this.getCurrencyById(currencyRightId),
        selectedItemLeft: this.getCurrencyById(currencyLeftId),
      });
    }
  }

  protected selectFirstCurrency = (item: CurrencyItem, column: string, e): void => {
    if (e.currentTarget.getAttribute('href') === '#') {
      e.preventDefault();
    }
    this.updateAvailableCurrencies(item.id, column);
  };

  protected isHidden = (id: string, column: string): boolean => {
    const {
      selectedItemRight, hiddenItemsIdListLeft,
      selectedItemLeft, hiddenItemsIdListRight,
    } = this.state;
    let hidden = false;

    if (column === COL_LEFT) {
      hidden = hiddenItemsIdListLeft[id] || (selectedItemRight && id === selectedItemRight.id);
    } else {
      hidden = hiddenItemsIdListRight[id] || (selectedItemLeft && id === selectedItemLeft.id);
    }

    return hidden;
  };

  protected generateLinkLeft = (item: CurrencyItem): string => {
    const { selectedItemRight } = this.state;
    const hidden = this.isHidden(item.id, COL_LEFT);

    return selectedItemRight && !hidden ? `/${item.rel}-to-${selectedItemRight.rel}/` : '#';
  };

  protected generateLinkRight = (item: CurrencyItem): string => {
    const { selectedItemLeft } = this.state;
    const hidden = this.isHidden(item.id, COL_RIGHT);

    return selectedItemLeft && !hidden ? `/${selectedItemLeft.rel}-to-${item.rel}/` : '#';
  };

  protected hideGroupLeft = (id: string): void => {
    const { hiddenGroupsIdListLeft } = this.state;

    hiddenGroupsIdListLeft[id] = !hiddenGroupsIdListLeft[id];

    this.setState({ hiddenGroupsIdListLeft: { ...hiddenGroupsIdListLeft } });
  };

  protected hideGroupRight = (id: string): void => {
    const { hiddenGroupsIdListRight } = this.state;

    hiddenGroupsIdListRight[id] = !hiddenGroupsIdListRight[id];

    this.setState({ hiddenGroupsIdListRight: { ...hiddenGroupsIdListRight } });
  };

  protected updateAvailableCurrencies = (id: string, col: string): void => {
    const post = col === COL_LEFT ? `id_f=${id}` : `id_c=${id}`;
    axios.post('/wp-content/plugins/exchangers-monitor/check.php', post, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'x-requested-with': 'XMLHttpRequest',
      },
    })
      .then(({ data }): void => {
        const allIdList = this.getCurrencyIdList();
        const hiddenItemsIdList = {};
        const {
          selectedItemLeft, selectedItemRight,
          hiddenItemsIdListRight, hiddenItemsIdListLeft,
        } = this.state;

        allIdList.forEach((i: string): void => {
          hiddenItemsIdList[i] = true;
        });

        data.forEach((i: string): void => {
          hiddenItemsIdList[i] = false;
        });

        if (col === COL_LEFT) {
          this.setState({
            hiddenItemsIdListLeft: hiddenItemsIdList,
            searchTextLeft: '',
            searchTextRight: '',
            selectedItemRight: this.getCurrencyById(id),
          });

          const selectedItem = selectedItemLeft && this.isHidden(selectedItemLeft.id, COL_LEFT)
            ? null : selectedItemLeft;

          this.setState({
            hiddenItemsIdListRight: selectedItem ? hiddenItemsIdListRight : {},
            selectedItemLeft: selectedItem,
          });
        } else {
          this.setState({
            hiddenItemsIdListRight: hiddenItemsIdList,
            searchTextLeft: '',
            searchTextRight: '',
            selectedItemLeft: this.getCurrencyById(id),
          });

          const selectedItem = selectedItemRight && this.isHidden(selectedItemRight.id, COL_RIGHT)
            ? null : selectedItemRight;

          this.setState({
            hiddenItemsIdListLeft: selectedItem ? hiddenItemsIdListLeft : {},
            selectedItemRight: selectedItem,
          });
        }
      });
  };

  protected getCurrencyIdList = (): string[] => {
    const { currencyList } = this.props;
    const itemIdList = [];
    Object.keys(currencyList).forEach((id: string): void => {
      currencyList[id].items.forEach((item: CurrencyItem): void => {
        itemIdList.push(item.id);
      });
    });

    return itemIdList;
  };

  protected getCurrencyById = (id: string): CurrencyItem => {
    const { currencyList } = this.props;
    let selectedItem = null;
    Object.keys(currencyList).forEach((groupId: string): void => {
      currencyList[groupId].items.forEach((item: CurrencyItem): void => {
        if (id === item.id) {
          selectedItem = item;
        }
      });
    });

    return selectedItem;
  };

  protected replaceSearches = (): void => {
    const { selectedItemLeft, selectedItemRight } = this.state;
    this.setState({
      searchTextLeft: '',
      searchTextRight: '',
      selectedItemLeft: selectedItemRight,
      selectedItemRight: selectedItemLeft,
    }, (): void => {
      window.location.href = this.generateLinkRight(selectedItemLeft);
    });
  };

  public render(): React.ReactElement {
    const { currencyList, trans } = this.props;
    const {
      hiddenGroupsIdListLeft, hiddenGroupsIdListRight, hiddenItemsIdListLeft,
      hiddenItemsIdListRight, selectedItemLeft, selectedItemRight,
      searchTextLeft, searchTextRight,
    } = this.state;
    return (
      <noindex>
        <div className="currency-left-table">
          <div className="row m-0 titles">
            <div className="col-6 py-1">{trans.give}</div>
            <div className="col-6 py-1">{trans.get}</div>
          </div>
          <div className="row m-0">
            <div className="col-6 p-1 position-relative">
              <input
                placeholder={
                  selectedItemLeft && !searchTextLeft
                    ? selectedItemLeft.name
                    : trans.search
                }
                value={searchTextLeft}
                className="form-control"
                onChange={(e): void => this.setState({ searchTextLeft: e.currentTarget.value })}
                type="text"
              />
              <button className="search-switcher" type="button" onClick={this.replaceSearches}>
                <i className="material-icons">import_export</i>
              </button>
            </div>
            <div className="col-6 p-1">
              <input
                placeholder={
                  selectedItemRight && !searchTextRight
                    ? selectedItemRight.name
                    : trans.search
                }
                value={searchTextRight}
                className="form-control"
                onChange={(e): void => this.setState({ searchTextRight: e.currentTarget.value })}
                type="text"
              />
            </div>
          </div>
          <div className="row m-0">
            <div className="col-6 p-0">
              {Object.keys(currencyList)
                .sort((a, b): number => (
                  currencyList[a].position > currencyList[b].position ? 1 : -1
                ))
                .map((id: string): React.ReactElement => (
                  <>
                    <button
                      type="button"
                      className="btn btn-expand"
                      onClick={(): void => this.hideGroupLeft(id)}
                    >
                      <i
                        style={{ marginLeft: 3, marginRight: 5 }}
                        className={
                          `fa ${hiddenGroupsIdListLeft[id] && searchTextLeft === ''
                            ? 'fa-plus-square-o'
                            : 'fa-minus-square-o'}`
                        }
                      />
                      {currencyList[id].name}
                    </button>
                    {hiddenGroupsIdListLeft[id] && searchTextLeft === ''
                      ? null
                      : currencyList[id].items
                        .filter((item: CurrencyItem): boolean => (
                          searchTextLeft === ''
                            || item.name.includes(searchTextLeft)
                            || item.name.toLowerCase().includes(searchTextLeft)
                        ))
                        .map((item: CurrencyItem): React.ReactElement => (
                          <a
                            key={item.id}
                            className={`list-button text-truncate ${
                              selectedItemLeft
                                && item.id === selectedItemLeft.id ? 'active' : ''} ${hiddenItemsIdListLeft[item.id]
                                || (selectedItemRight && item.id === selectedItemRight.id) ? 'not-active' : ''}`}
                            type="button"
                            title={item.name}
                            onClick={(e): void => this.selectFirstCurrency(item, COL_RIGHT, e)}
                            href={this.generateLinkLeft(item)}
                          >
                            {item.name}
                          </a>
                        ))}
                  </>
                ))}
            </div>
            <div className="col-6 p-0">
              {Object.keys(currencyList)
                .sort((a, b): number => (
                  currencyList[a].position > currencyList[b].position ? 1 : -1
                ))
                .map((id: string): React.ReactElement => (
                  <>
                    <button
                      type="button"
                      className="btn btn-expand"
                      onClick={(): void => this.hideGroupRight(id)}
                    >
                      <i
                        style={{ marginLeft: 3, marginRight: 5 }}
                        className={`fa ${hiddenGroupsIdListRight[id] && searchTextRight === '' ? 'fa-plus-square-o' : 'fa-minus-square-o'}`}
                      />
                      {currencyList[id].name}
                    </button>
                    {hiddenGroupsIdListRight[id] && searchTextRight === ''
                      ? null
                      : currencyList[id].items
                        .filter((item: CurrencyItem): boolean => (
                          searchTextRight === ''
                            || item.name.includes(searchTextRight)
                            || item.name.toLowerCase().includes(searchTextRight)
                        ))
                        .map((item: CurrencyItem): React.ReactElement => (
                          <a
                            key={item.id}
                            className={
                              `list-button text-truncate ${selectedItemRight
                                && item.id === selectedItemRight.id ? 'active' : ''} ${hiddenItemsIdListRight[item.id]
                                || (selectedItemLeft && item.id === selectedItemLeft.id) ? 'not-active' : ''}`
                            }
                            title={item.name}
                            onClick={(e): void => this.selectFirstCurrency(item, COL_LEFT, e)}
                            href={this.generateLinkRight(item)}
                          >
                            {item.name}
                          </a>
                        ))}
                  </>
                ))}
            </div>
          </div>
        </div>
      </noindex>
    );
  }
}

type CurrenciesLeftTableProps = {
  currencyList: CurrencyList;
  currencyLeftId?: string;
  currencyRightId?: string;
  trans: {[key: string]: string};
};

type CurrenciesLeftTableState = {
  selectedItemLeft: CurrencyItem;
  selectedItemRight: CurrencyItem;
  searchTextLeft: string;
  searchTextRight: string;
  hiddenGroupsIdListLeft: {[id: string]: boolean};
  hiddenGroupsIdListRight: {[id: string]: boolean};
  hiddenItemsIdListLeft: {[id: string]: boolean};
  hiddenItemsIdListRight: {[id: string]: boolean};
};
