import * as React from 'react';

export default class LangSwitcher extends React.PureComponent
  <LangSwitcherProps, LangSwitcherState> {
  public state: LangSwitcherState = {
    selected: null,
    opened: false,
  };

  public componentDidMount(): void {
    const { langArray } = this.props;
    langArray.forEach((lang): void => {
      if (lang.selected) {
        this.setState({ selected: lang });
      }
    });
  }

  protected selectLang = (lang): () => void => (): void => {
    this.setState({ selected: lang });
  };

  protected openSelect = (): void => {
    const { opened } = this.state;
    this.setState({ opened: !opened });
  };

  public render(): React.ReactElement {
    const { langArray } = this.props;
    const { selected, opened } = this.state;

    return (
      <div className="dropdown ml-3 lang-switcher">
        <button
          className="btn text-white dropdown-toggle px-3"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={this.openSelect}
        >
          {selected ? <img src={selected.flag} className="mr-1" alt="Flag" /> : ''}
          {selected ? selected.name : ''}
        </button>
        <div className={`dropdown-menu w-100 mw-100 py-1 ${opened ? 'show' : ''}`}>
          {langArray.map((item: Lang): React.ReactElement => (
            <a
              className={`dropdown-item px-3 ${selected && item.slug === selected.slug ? 'active' : ''}`}
              href={item.url}
              onClick={this.selectLang(item)}
            >
              <img src={item.flag} className="mr-1" alt="Flag" />
              {item.name}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

type Lang = {
  name: string;
  flag: string;
  slug: string;
  url: string;
  selected: boolean;
};

type LangSwitcherProps = {
  langArray: Lang[];
};

type LangSwitcherState = {
  selected: Lang;
  opened: boolean;
};
